import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, TextField } from "@mui/material";
import {
  Facebook,
  Google,
  Email,
  Language,
  RadioButtonCheckedOutlined,
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import { addDoc, collection } from "@firebase/firestore";
import { firestore } from "../utils/firebase";
import { async } from "@firebase/util";
import Offer from "./offer";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Notification from "../utils/notification";

export default function Form({ onSubmit, isClear, plan }) {
  const [formData, setFormData] = useState(plan.prev_state);

  const [isAdded, setIsAdded] = useState(false);

  const [notify, setNotify] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
    setNotify(true);
    setIsAdded(true);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setIsAdded(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 border-line">
          <div className="first-box">
            <h2>What is your plan for {plan.month_name}</h2>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid className="item-box" item xs={12} sm={12} md={8} lg={8}>
                  <h3>Your Offer & Activites</h3>

                  <Box className="plan-div">
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      className="textarea-activities"
                      rows={8}
                      name="activities"
                      value={formData.activities}
                      onChange={handleChange}
                      placeholder="Type your offer & Activities: Max 150 words"
                    />
                  </Box>
                </Grid>

                <Grid className="item-box" item xs={12} sm={12} md={4} lg={4}>
                  <h3>Your Budget</h3>

                  <Box className="plan-div">
                    Paid campaigns on
                    <ul className="social_list">
                      <li className="list-social-items">
                        <Facebook className="facebook-icon" fontSize="large" />

                        <TextField
                          className="social-textfield"
                          placeholder="£ budget for facebook"
                          name="facebook"
                          onChange={handleChange}
                          value={formData.facebook}
                          size="small"
                        />
                      </li>

                      <li className="list-social-items">
                        <Google className="google-icon" fontSize="large" />

                        <TextField
                          className="social-textfield"
                          placeholder="£ budget for google"
                          name="google"
                          onChange={handleChange}
                          value={formData.google}
                          size="small"
                        />
                      </li>

                      <li className="list-social-items">
                        <Email className="email-icon" fontSize="large" />
                        <TextField
                          className="social-textfield"
                          placeholder="£ budget for email"
                          name="email"
                          onChange={handleChange}
                          value={formData.email}
                          size="small"
                        />
                      </li>
                      <li className="list-social-items">
                        <Language className="language-icon" fontSize="large" />
                        <TextField
                          className="social-textfield"
                          placeholder="£ budget for other"
                          onChange={handleChange}
                          name="other"
                          value={formData.other}
                          size="small"
                        />
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>

          <Offer key={`id_${plan.id}`} schema={plan} />

          <TextField
            id="outlined-multiline-static"
            multiline
            className="textarea-activities"
            rows={7}
            name="ideas"
            value={formData.ideas}
            onChange={handleChange}
            placeholder="Your Suggestions / Ideas: Max 150 Words"
          />
        </div>
        <Button
          type="submit"
          className="submit-btn"
          color="success"
          variant="contained"
          disabled={isAdded}
        >
          Add data for {plan.month_name}
        </Button>
      </form>

    </>
  );
}
