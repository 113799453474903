import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { RadioButtonCheckedOutlined } from "@mui/icons-material";
import { collection, query, where, getDocs } from "firebase/firestore";
import Button from "@mui/material/Button";
import { firestore } from "../utils/firebase";
import { isMobile } from "mobile-device-detect";

export default function CalenderList() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const getLogout = () => {
    localStorage.removeItem("useremail");
    window.location.reload();
  }

  const checkMonth = (month_name) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
  };

  const fetchEvents = async () => {
    const arr = [];
    const q = query(
      collection(firestore, "Months"),
      where("months.status", "==", true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data().months);
    });

    setEvents(arr);
  };

  return (
    <Box>
      <Box>
        <Button
          type="submit"
          className="logout-btn"
          color="success"
          variant="contained"
          onClick={getLogout}
        >
          Logout
        </Button>
      </Box>
      <Box
        className="list-header"
        sx={{
          backgroundImage: isMobile
            ? `url('/image/header_mobile.jpg')`
            : `url('/image/header_desktop.jpg')`,
        }}
      ></Box>
      <Grid container className="events-background-home">
        {events?.map((item, index) => {
          return (
            <Grid
              key={index}
              className="card-items"
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
            >
              <a href={`#${item.month_name}`}>
                <Box
                  className="items-div"
                  onClick={() => checkMonth(item.month_name)}
                  style={{ backgroundImage: `url(${item.background_image})` }}
                >
                  <Box className="contents">
                    <h2 className="event-month">{item.month_name}</h2>
                    <ul
                      className="event-list-ul"
                      style={{ color: item.font_color }}
                    >
                      {item.events?.map((event, i) => {
                        return (
                          <li key={`event_${i}`}>
                            <RadioButtonCheckedOutlined fontSize="small" />{" "}
                            {event}
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                </Box>
              </a>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
