import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyBFNVtUDlcrEBtwrVnu0TbD_KrjoOhEFCg",
  authDomain: "event-calender-3957c.firebaseapp.com",
  projectId: "event-calender-3957c",
  storageBucket: "event-calender-3957c.appspot.com",
  messagingSenderId: "250416000900",
  appId: "1:250416000900:web:174a2ccaff788421a7b7d1"
};

const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);