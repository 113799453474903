import React, { useState, useEffect } from "react";
import Calender from "../components/calender";
import CalenderList from "../components/calenderList";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { firestore } from "../utils/firebase";
import UserLogin from "./UserLogin";

import userEvent from "@testing-library/user-event";

export default function Home() {
  const [data, setData] = useState([]);
  const [userLogin, setUserLogin] = useState(false);
  const user = localStorage.getItem("useremail");

  useEffect(() => {
    if (user) {
      setUserLogin(true);
    } else {
      setUserLogin(false);
    }
  }, [user]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const arr = [];
    const q = query(
      collection(firestore, "Schema"),
      where("schema.status", "==", true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data().schema);
    });

    const index = arr.findIndex(
      (item) => item.month_name === monthNames[d.getMonth()]
    );

    setData(arr.slice(index, arr.length));
  };

  return (
    <div className="App">
      <header>
        {!userLogin && <UserLogin />}
        {userLogin && (
          <>
            <CalenderList />
            <Calender plans={data} />
          </>
        )}
      </header>
    </div>
  );
}
