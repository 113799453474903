import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";



export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const user = localStorage.getItem("email");

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);

  function handleSignIn(event) {
    event.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem("email", user.email);
        navigate("/dashboard");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  return (
    <Box className="events-background">
      <div className="container-login">
        <form onSubmit={handleSignIn}>
          <Box className="form-items">
            <TextField
              id="outlined-basic"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label="Email"
            />
          </Box>

          <Box className="form-items">
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              autoComplete="current-password"
            />
          </Box>

          <Button
            type="submit"
            className="submit-btn"
            color="success"
            variant="contained"
          >
            Submit
          </Button>
        </form>
      </div>
    </Box>
  );
}
