import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, TextField } from "@mui/material";
import {
  Facebook,
  Google,
  Email,
  Language,
  RadioButtonCheckedOutlined,
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export default function Offer({ schema }) {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <div className="our-plan-title item-box">
          <h2>Our plan for {schema.month_name}</h2>
        </div>
        <Grid container>
          <Grid className="item-box" item xs={12} sm={12} md={6} lg={6}>
            {schema?.plan_left.map((p_left) => {
              return (
                <div>
                  <div className="">
                    <h5 className="plan-title">
                      {p_left.title}
                      <br />
                      {p_left.subtitle && (
                        <span className="plan-subtitle">{p_left.subtitle}</span>
                      )}
                    </h5>
                    <ul style={{color: schema.highlight_text_color, textAlign: 'left'}}>
                      {p_left.features?.map((feature, index) => {
                        return (
                          <li key={`feature_${index}`}>
                            <RadioButtonCheckedOutlined fontSize="small" />{" "}
                            {feature}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </Grid>

          <Grid className="item-box" item xs={12} sm={12} md={6} lg={6}>
            {schema?.plan_right.map((p_right) => {
              return (
                <div className="">
                  <div className="">
                    <h5 className="plan-title">
                      {p_right.title}
                      <br />
                      {p_right.subtitle && (
                        <span className="plan-subtitle">{p_right.subtitle}</span>
                      )}
                    </h5>
                    <ul style={{color: schema.highlight_text_color, textAlign: 'left'}}>
                      {p_right.features?.map((feature, index) => {
                        return (
                          <li key={`feature1_${index}`}>
                            <RadioButtonCheckedOutlined fontSize="small" />{" "}
                            {feature}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
