import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import { firestore } from "../utils/firebase";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { addDoc } from "@firebase/firestore";
import Notification from "../utils/notification";

export default function UserLogin() {
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ownerName, setOwnerName] = useState("");

  const [status, Setstatus] = useState("");

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (companyName === "" || ownerName === "" || email === "") {
      Setstatus("warning");
      return;
    }

    const q = query(
      collection(firestore, "Events"),
      where("events.email", "==", email)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      let data = {
        company_name: companyName,
        owner_name: ownerName,
        email: email,
      };

      try {
        const docRef = await addDoc(collection(firestore, "Events"), {
          events: data,
        });
        Setstatus("success");
        localStorage.setItem("useremail", data.email);
        window.location.reload();
      } catch (e) {
        Setstatus("error");
        console.error("Error adding document: ", e);
      }
    } else {
      localStorage.setItem("useremail", email);
      Setstatus("back");
      window.location.reload();
    }
  };

  return (
    <Box className="events-background">
      <div className="container-login">
        <form onSubmit={handleSignIn}>
          <Box className="form-items">
            <TextField
              id="outlined-basic"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label="Email"
            />
          </Box>

          <Box className="form-items">
            <TextField
              id="outlined-password-input"
              label="Company Name"
              type="text"
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
              autoComplete="current-password"
            />
          </Box>

          <Box className="form-items">
            <TextField
              id="outlined-password-input"
              label="Owner Name"
              type="text"
              value={ownerName}
              onChange={(event) => setOwnerName(event.target.value)}
              autoComplete="current-password"
            />
          </Box>

          <Button
            type="submit"
            className="submit-btn"
            color="success"
            variant="contained"
          >
            Submit User Info
          </Button>
        </form>

        {status === "success" && (
          <Notification
            isOpen={true}
            severity="success"
            msg="Sign up Successfull"
          />
        )}

        {status === "back" && (
          <Notification
            isOpen={true}
            severity="success"
            msg="Welcome Back"
          />
        )}

        {status === "warning" && (
          <Notification
            isOpen={true}
            severity="warning"
            msg="Please fill up all fields"
          />
        )}

        {status === "error" && (
          <Notification isOpen={true} severity="error" msg="Something Error" />
        )}
      </div>
    </Box>
  );
}
