import React, { useState, useEffect } from "react";
import { firestore } from "../utils/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Navbar from "../components/navbar";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

export default function Events() {
  const user = localStorage.getItem("email");
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchEvents();
    } else {
      navigate("/login");
    }
  }, [user]);

  const fetchEvents = async () => {
    const arr = [];
   
    const q = query(collection(firestore, "Events"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
     
      const newOBJ = {
        info: doc.data().events,
        plans: doc.data().plans?doc.data().plans: []
      }

      arr.push(newOBJ);
    });

   
   
    setEvents(arr);
  };



  return (
    <>
      <Navbar />
      <Box className="events-background">
        {!events && <h2>No Events Found</h2>}

        {events.map((event, i) => {
          return (
            <Box key={`${event.info.owner_name}_${i}`}>
              <Box className="table-title-box">
                <h3>{event.info.company_name}</h3>
                <h3>{event.info.owner_name}</h3>
                <h3>{event.info.email}</h3>
              </Box>
              <Box className="table-box">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Month</TableCell>
                        <TableCell>Facebook as £</TableCell>
                        <TableCell>Google as £</TableCell>
                        <TableCell>Email Marketing as £</TableCell>
                        <TableCell>Other as £</TableCell>

                        <TableCell>Offer & Activites</TableCell>
                        <TableCell>Given Suggestions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {event.plans?.map((plan, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {plan.month}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {plan.facebook}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {plan.google}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {plan.email}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {plan.other}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {plan.activities}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {plan.ideas}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
