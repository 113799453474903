import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const auth = getAuth();
  const navigate = useNavigate();
  function handleSignOut() {
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("email");
        navigate("/login");
      })
      .catch((error) => {
        // handle sign out error
      });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Button>
            <Link className="nav-item" to={"/"}>Homepage</Link>
          </Button>

          <Button onClick={handleSignOut} color="inherit">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
