import React, { useEffect, useState } from "react";
import { Typography, TextField } from "@mui/material";

import Button from "@mui/material/Button";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { firestore } from "../utils/firebase";

import Box from "@mui/material/Box";
import Notification from "../utils/notification";
import Form from "./Form";
import Modal from "@mui/material/Modal";

const yr = new Date()
const yrShort = yr.toLocaleDateString(undefined, { year: "2-digit"})

export default function Calender({ plans }) {
  const [formDatas, setFromData] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [updatePlans, setUpdatePlans] = useState(plans);

  const [submittedMonth, setSubmittedMonth] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = (formData) => {
    setSubmittedMonth(formData.month);
    const item_index = formDatas.findIndex(
      (item) => item.month === formData.month
    );
    if (item_index >= 0) {
      const newArray = [...formDatas];
      newArray.splice(item_index, 1, formData);
      setFromData(newArray);
    } else {
      setFromData([...formDatas, formData]);
    }

    setOpen(true);
  };

  const [isClear, setIsClear] = useState(false);

  const getUser = localStorage.getItem("useremail");

  useEffect(() => {
    fetchUserEvents();
  }, [plans, getUser]);

  const fetchUserEvents = async () => {
    const q = query(
      collection(firestore, "Events"),
      where("events.email", "==", getUser)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      
      if (doc.data().plans) {
        if (doc.data().plans.length > 0) {
          

          const updatePlans = plans.map((obj) => {
            const matchingPlan = doc.data().plans.find((plan) => obj.month_name === plan.month);
            if (matchingPlan) {
              return { ...obj, prev_state: { ...matchingPlan } };
            } else {
             
              return { ...obj, prev_state: {  facebook: "",
                  google: "",
                  email: "",
                  other: "",
                  month: obj.month_name,
                  activities: "",
                  ideas: "", } };
            }
            
          });
          
          setUpdatePlans(updatePlans);
          setFromData(doc.data().plans);
        }
      } else {
        const updatePlans = plans.map((obj) => ({
          ...obj,
          prev_state: {
            facebook: "",
            google: "",
            email: "",
            other: "",
            month: obj.month_name,
            activities: "",
            ideas: "",
          },
        }));
        setUpdatePlans(updatePlans);
        setFromData([]);
      }
    });
  };

  const [status, Setstatus] = useState("");

  const handleSubmitData = async (e) => {
    e.preventDefault();
    setOpen(false);

    try {
      const q = await getDocs(
        query(
          collection(firestore, "Events"),
          where("events.email", "==", localStorage.getItem("useremail"))
        )
      );

      q.forEach((doc) => {
        updateDoc(doc.ref, {
          plans: formDatas,
        });
      });

      Setstatus("success");
      setIsClear(true);
    } catch (e) {
      Setstatus("error");
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      {updatePlans.map((plan, index) => {
        return (
          <div
            className="App-header"
            id={plan.month_name}
            style={{ backgroundImage: `url(${plan.background_image})` }}
          >
            <div className="container">
              <div className="page_title">
                <h2 className="plan-title-main">
                  SMM Plan for {plan.month_name}-{yrShort}
                </h2>
              </div>

              <Form
                key={index}
                onSubmit={handleSubmit}
                isClear={isClear}
                plan={plan}
              />

              {status === "success" && (
                <Notification
                  isOpen={true}
                  severity="success"
                  msg="Form Submission Successfull"
                />
              )}

              {status === "warning" && (
                <Notification
                  isOpen={true}
                  severity="warning"
                  msg="Please fill up company & owner Name fields"
                />
              )}

              {status === "error" && (
                <Notification
                  isOpen={true}
                  severity="error"
                  msg="Something Error"
                />
              )}
            </div>
          </div>
        );
      })}

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Do you want to save the form?
          </Typography>

          <Box>
            <Button
              type="submit"
              className="submit-btn"
              color="success"
              sx={{ marginRight: "5px" }}
              variant="contained"
              onClick={handleClose}
            >
              NO
            </Button>

            <Button
              type="submit"
              className="submit-btn"
              color="success"
              variant="contained"
              onClick={handleSubmitData}
            >
              YES
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
